<template>
  <div>
     <h1 :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
        'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Premios</h1>   
     <br><br>
    <h6  style="color:#fff;  font-size: 19px;">
     “Tenemos espacios reservados en la Convención Comercial 2023 para los gerentes y ejecutivos seleccionados de los Call Center de Canal TLMKT que obtengan el mayor puntaje al final del juego, apegados a los criterios de selección del concurso.” 
    </h6>
    <br>
    <h4 style="color:#29486F;"><b>Estructura General</b></h4>
    <br>
    <p  style="color:#fff;  font-size: 19px;">
        El juego consta de 2 ejes: logros y aceleradores.   <br><br>

        <b>Logros: </b> <br>
        Relacionados con el cumplimiento de cuota.<br><br>

       <b>Acelerador: </b> <br>
        Relacionados con impulsar y premiar el cumplimiento de cuota. <br><br>


    </p>

        <h5 style="color:#29486F;"><b>LOGROS + ACELERADORES = Total de Puntos por Participante.  </b></h5> <br>
         <p  style="color:#fff;  font-size: 19px;">
           Los participantes podrán revisar su avance dentro de la plataforma del concurso.
         </p>  
    <br><br>
  </div>
</template>

<script>
export default {
 data(){
      return{
            isMobile: this.$mobile(),
      }
 },
 mounted()
  {
      if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo6", true)
      } 
  }
}
</script>

<style>

</style>