<template>
  <div>
        <!-- <h1 :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
        'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Mecanica</h1>   -->
        <img  src="elementos/mecanica2.png" class="rounded" alt="Image"  > 
        <br>
        <h6  style="color:#fff; text-align: center;  font-size: 19px;">
           <b>Eje.1 - Logros:</b>  El objetivo de este eje es superar las cuotas comerciales de manera mensual,
           ya que los puntos que se pueden obtener están 100% relacionados con el cumplimiento de las cuotas mensuales. 
        </h6>
        <br>
        <template v-if="user.perfil == 'Lider'">
        <h4 style="color:#29486F;"><b>Líderes</b></h4>
          <br>
          <p style="text-align: justify; color:#fff;  font-size: 19px;">
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b> 
                Cumplimiento cuota mensual de <b>prepago, terminales e ingresos.</b>
                <br><br>
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">2.</b>
                El cumplimiento de cuota es equivalente a puntos, es decir: logro de 110% = 110 puntos. 
                <br><br> 
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">3.</b>
              Los puntos se acumulan a lo largo de los 9 meses que dura el concurso: 1 abril al 31 de diciembre.
          </p>
        <br>
       </template>
       <template v-else>
        <h4 style="color:#29486F;"><b>Promotores</b></h4>
          <br>
          <p style="text-align: justify; color:#fff;  font-size: 19px;">
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b> 
                Se escogerán los mejores PDV por región según el mayor logro de cuotas a lo largo del concurso.
                Las Agencias seleccionarán al número de promotores que les indique la tabla de ganadores, de estos PDV,  según los criterios de premiación.
                <br><br>
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">2.</b>
                Cumplimiento cuota mensual de <b>prepago y terminales Alta Temm.</b>
                <br><br> 
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">3.</b>
                El cumplimiento de cuota es equivalente a puntos, es decir: logo de 110% = 110 puntos. 
                <br><br>
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">4.</b>
                Los puntos se acumulan a lo largo de los 9 meses que dura el concurso: 1 abril al 31 de diciembre.
                <br><br> 
          </p>
        <br>
          <h4 style="color:#29486F;"><b>Supervisores</b></h4>
          <br>
          <p style="text-align: justify; color:#fff;  font-size: 19px;">
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b> 
                Se utilizarán los clústeres de los líderes internos para determinar el cumplimiento
                y del clúster ganador, las Agencias seleccionarán a los mejores supervisores por región (suma de puntos de venta a cargo) con el mejor logro de cuotas a lo largo del concurso.
                <br><br> 
               <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">2.</b>
                Cumplimiento cuota mensual de <b>prepago y terminales Alta Temm.</b>
                <br><br> 
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">3.</b>
                El cumplimiento de cuota es equivalente a puntos, es decir: logo de 110% = 110 puntos. 
                <br><br>
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">4.</b>
                Los puntos se acumulan a lo largo de los 9 meses que dura el concurso: 1 abril al 31 de diciembre.
                <br><br> 
          </p>
        <br>
       </template>
        <h6  style="color:#fff; text-align: justify;"> 
          <b>Eje.2 - Aceleradores:</b>  El objetivo de este eje es impulsar el cumplimiento de cuotas y premiar el sobre cumplimiento de las mismas de manera mensual: 
        </h6>
        <br> 
        <h4 style="color:#29486F;"><b>Esquema de Aceleradores</b></h4>
        <br>
       <p style="text-align: justify; color:#fff;  font-size: 19px;">
            <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b> 
              Se otorgaran 10 puntos adicionales a quien alcance entre el 95% y 100% de cumplimiento de cuota. 
              <br><br>
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">2.</b>
             Se otorgaran 20 puntos adicionales a partir de cumplimiento de 101% de cuota.  
        </p>   
         <br>
        <h6  style="color:#fff; text-align: justify;  font-size: 19px;" v-if="user.perfil == 'Lider'"> 
          <b>Eje.3 - Capacitaciones:</b> El objetivo de este eje es participar y completar las capacitaciones que se vayan proponiendo dentro del concurso y concluirlas dentro del mes establecido.
            El valor de cada Capacitación es de 40 puntos. 
        </h6> 
        <h6  style="color:#fff; text-align: justify;  font-size: 19px;" v-else> 
          <b>Eje.3 - Capacitaciones:</b> El objetivo de este eje es participar y completar las capacitaciones que se vayan proponiendo dentro del concurso.
                     Se entregará al Canal el listado con las personas que se capacitaron para que las Agencias los consideren dentro de sus ganadore seleccionados.
        </h6>
        <br> 
       <template v-if="user.perfil == 'Lider'">
         <h6  style="color:#fff; text-align: justify;  font-size: 19px;"> 
            <b>Eje.4 - Desafíos:</b> El objetivo de este eje es participar y completar las capacitaciones que se vayan proponiendo dentro del concurso. Se entregará al Canal el listado con las 
              personas que se capacitaron para que las Agencias los consideren dentro de sus ganadores seleccionados.
          </h6> 
          <br> 
          <p style="text-align: justify; color:#fff;  font-size: 19px;">
                <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b> 
                El valor de cada actividad es de 10 puntos.   
                <br><br>
                <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">2.</b>
                Los participantes deberán de responder de manera correcta las trivias, mini juegos y preguntas secretas en el orden que se vayan lanzando.  <br>
                • Trivias - Las preguntas estarán relacionadas con la oferta comercial, información presentada en boletines o comunicados comerciales, plataformas, atención al cliente, etc.  <br>
                • Mini juegos - Completar lo juegos de manera correcta.  <br>
                • Pregunta secreta - pregunta abierta  
                <br><br>
                <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">3.</b>
                Solo se tomarán en cuenta los puntos obtenidos de los desafíos si
                el participante completa la capacitación correspondiente al mismo mes.
                De haber un empate se tomarán en cuenta las actividades contestadas correctamente en el orden que fueron entregadas.           
            </p>   
          <br>  
       </template>
  </div>
</template>

<script>
export default {
 data(){
      return{
            isMobile: this.$mobile(),
      }
 },
   computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
 mounted()
  {
      if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo5", true)
      } 
  }
}
</script>

<style>

</style>