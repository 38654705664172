<template>
  <div>
        <h1 :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
        'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Ganadores</h1> 
       <br>
        <h6  style="color:#fff; text-align: center;  font-size: 19px;"  v-if="user.canal == 3">
            “Ganarán los PDV y Clústeres por región con mayor cumplimiento al sumar los puntos obtenidos por logro de cuotas,
            capacitaciones y desafíos, que además cumplan con los criterios de selección.  
        </h6>
         <h6  style="color:#fff; text-align: center;  font-size: 19px;"  v-if="user.canal == 4"> 
            “Los  Líderes y KAMs con mayor cumplimiento al sumar los puntos obtenidos
            por logro de cuotas y capacitaciones, que cumplan los criterios de selección,
            serán los ganadores.” 
        </h6> 
        <h6  style="color:#fff; text-align: center;  font-size: 19px;"  v-if="user.canal == 2"> 
            “Ganaran los Call Centers con mayor cumplimiento por campaña al sumar los
            puntos obtenidos por logro de cuotas y aceleradores, que además cumplan con
            los criterios de selección.  Una vez determinados los Call Centers ganadores,
            los dueños escogerán dedichos sitios a los Ejecutivos y Gerentes ganadores.”
        </h6> 
         <h6  style="color:#fff; text-align: center;  font-size: 19px;"  v-if="user.canal == 1">
          <template v-if="user.perfil == 'Lider'">
             “Ganará el líder de cada región con mayor puntaje al sumar los puntos obtenidos por logro de cuotas, aceleradores, capacitaciones y desafíos, que cumplan los criterios de selección.” 
           
          </template>
          <template v-else>
             “Ganaran los PDV y Clústeres por región y agencia, con mayor puntaje
             al sumar los puntos obtenidos por logro de cuotas, aceleradores y capacitaciones,
             que además cumplan con los criterios de selección.  Una vez determinados los PdV y
             clústeres ganadores, las Agencias escogerán de dichos sitios a los Promotores y Supervisores ganadores.” 
           </template>
        </h6>
        <br>
        <h4 style="color:#29486F;"><b>Ejemplos de conteo de puntos:</b></h4>
        <br>
        <center  v-if="user.canal == 3">
          <img src="elementos/propio11.png" alt="Image"  :style="isMobile ? 'width: 100%;' : 'width: 80%;'" v-if="user.perfil == 'Coordinador'">
          <img src="elementos/propio33.png" alt="Image"  :style="isMobile ? 'width: 100%;' : 'width: 80%;'" v-else>
        </center> 
        <center  v-if="user.canal == 4"><img src="elementos/gan_esp1.png" alt="Image" :style="isMobile ? 'width: 100%;' : 'width: 50%;'"></center> 
        <center  v-if="user.canal == 1">
          <img v-if="user.perfil == 'Lider'" src="elementos/retail_lider1.png" alt="Image"  :style="isMobile ? 'width: 100%;' : 'width: 80%;'">
          <img v-else src="elementos/retail_promo1.png" alt="Image"  :style="isMobile ? 'width: 100%;' : 'width: 80%;'">
        </center> 
        <center  v-if="user.canal == 2">
            <img src="elementos/tlmkt11.png" alt="Image"  :style="isMobile ? 'width: 100%;' : 'width: 80%;'"> <br><br>
            <img src="elementos/tlmkt22.png" alt="Image"  :style="isMobile ? 'width: 100%;' : 'width: 80%;'">
        </center> 
        <br>
  </div>
</template>

<script>
export default {
 data(){
      return{
            isMobile: this.$mobile(),
      }
 },
   computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
 mounted()
  {
      if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo", true)
      } 
  }
}
</script>

<style>

</style>