var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{style:(_vm.isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
      'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;')},[_vm._v(" Bases del Concurso "),(_vm.user.canal == 3)?_c('span',{style:(_vm.isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
          'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;')},[_vm._v("Propio")]):_vm._e(),(_vm.user.canal == 2)?_c('span',{style:(_vm.isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
          'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;')},[_vm._v("TLMKT")]):_vm._e(),(_vm.user.canal == 1)?_c('span',{style:(_vm.isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
          'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;')},[_vm._v("Retail")]):_vm._e(),(_vm.user.canal == 4)?_c('span',{style:(_vm.isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
          'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;')},[_vm._v("Especialista")]):_vm._e()]),_c('br'),_c('br'),_vm._m(0),_c('br'),_c('p',{staticStyle:{"color":"#fff","text-align":"center","font-size":"19px"}},[_vm._v(" Queremos premiar el esfuerzo y dedicación de nuestra fuerza de venta a nivel nacional, evaluando su desempeño en todos los ámbitos como su conocimiento del negocio, nivel de capacitación y especialmente el cumplimiento constante de las cuotas comerciales. ")]),_c('br'),_vm._m(1),_c('br'),_c('p',{staticStyle:{"color":"#fff","text-align":"center","font-size":"19px"}},[_vm._v(" Del 1° de abril al 31 de diciembre 2023 ")]),_c('br'),_vm._m(2),_c('br'),_vm._m(3),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"color":"#29486F"}},[_c('b',[_vm._v("Objetivo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"color":"#29486F"}},[_c('b',[_vm._v("Fecha")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"color":"#29486F"}},[_c('b',[_vm._v("Quien participa")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"color":"#fff","text-align":"center","font-size":"19px"}},[_vm._v(" Participan Todos los Call Centers vigentes de Canal Telemarketing. "),_c('br'),_vm._v(" Quedan inscritos automáticamente y se empieza a contabilizar el cumplimiento de cuota desde el 1° de abril. ")])
}]

export { render, staticRenderFns }