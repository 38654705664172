<template>
  <div>
        <!-- <h1 :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
        'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Mecanica</h1>   -->
        <img  src="elementos/mecanica2.png" class="rounded" alt="Image"  > 
        <br>
        <h6  style="color:#fff; text-align: center;  font-size: 19px;">
          <b>Eje.1 - Logros:</b>  El objetivo de este eje es superar las cuotas comerciales de manera mensual, ya que los puntos que se pueden obtener están 100% relacionados con el cumplimiento de las cuotas mensuales.
        </h6>
        <br>
        <h4 style="color:#29486F;"><b>Ejecutivos y Gerentes</b></h4>
        <br>
        <p style="text-align: justify; color:#fff;  font-size: 19px;">
            <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b> 
             Cumplimiento cuota mensual de <b>prepago, pospago, migraciones, renovaciones, upsell y asistido.</b>
              <br><br>
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">2.</b>
              El cumplimiento de cuota es equivalente a puntos, es decir: logro de 110% = 110 puntos. 
              <br><br> 
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">3.</b>
              De cada una de las campañas gana el Call Center con mayor cumplimiento, de ahí los dueños seleccionaran al director/gerente y ejecutivos ganadores de acuerdo al número que se le indique.
             <br><br> 
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">4.</b>
              Si un Call Center gana 2 o más campañas y está el mismo director/gerente de líder, se le contabilizará la Campaña en la que haya ganado más puntos y la otra campaña en la que gano pasará al 2do lugar de esa categoría.
              <br><br> 
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">5.</b>
              Los puntos se acumulan a lo largo de los 9 meses que dura el concurso: 1 abril al 31 de diciembre. 
        </p>
       <br>
        <h6  style="color:#fff; text-align: justify;"> 
          <b>Eje.2 - Aceleradores:</b>  El objetivo de este eje es impulsar el cumplimiento de cuotas y premiar el sobre cumplimiento de las mismas de manera mensual:
        </h6>
        <br>
        <br>
          <h4 style="color:#29486F;"><b>Esquema de Aceleradores</b></h4>
        <br>
        <p style="text-align: justify; color:#fff;  font-size: 19px;">
              <b style="color:#29486F; font-size: 20px;">1.</b> 
                Se otorgaran 25 puntos adicionales a quien alcance o supere el 95% de cumplimiento de cuota.  
                <br><br>
              <b style="color:#29486F; font-size: 20px;">2.</b> 
                A partir de JULIO se añadirá un acelerador extra por parte del Canal. 
          </p>   
         <br>  
  </div>
</template>

<script>
export default {
 data(){
      return{
            isMobile: this.$mobile(),
      }
 },
 mounted()
  {
      if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo9", true)
      } 
  }
}
</script>

<style>

</style>