<template>
  <div>
     <h1 :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
        'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Premios</h1>   
     <br><br>
    <h6  style="color:#fff;  font-size: 19px;">
     “Tenemos espacios reservados en la Convención Comercial 2023 para 
     
        <span v-if="user.perfil == 'Lider'">los líderes de Canal Retail</span>
        <span v-else>las Agencias de Canal Retail</span>
      
       que obtengan el mayor puntaje al final del juego, apegados a los criterios de selección del concurso.” 
    </h6>
    <br>
    <h4 style="color:#29486F;"><b>Estructura General</b></h4>
    <br>
    <p  style="color:#fff;  font-size: 19px;">
        El juego consta de
         <span v-if="user.perfil == 'Lider'">4 ejes: logros, aceleradores, capacitación y desafíos.</span><span v-else>3 ejes: logros, aceleradores y capacitación. </span>    <br><br>

        <b>Logros: </b> <br>
        Relacionados con el cumplimiento de cuota. <br><br>

       <b>Acelerador: </b> <br>
        Relacionados con impulsar y premiar el cumplimiento de cuota. <br><br>

        <b>Capacitación:</b> <br>
        Relacionados con su participación en los programas de capacitación. <br><br>
        
        <template v-if="user.perfil == 'Lider'">
          <b>Desafíos:</b> <br>
          3 tipos de misiones: trivias, mini juegos y pregunta secreta.  Los participantes podrán ir completando las diferentes actividades con lo que irán sumando puntos. <br><br>
        </template>
    </p>

        <h5 style="color:#29486F;"><b><span v-if="user.perfil == 'Lider'">LOGROS + ACELERADORES + CAPACITACIÓN + DESAFÍOS</span>
        <span v-else>LOGROS + ACELERADORES + CAPACITACIÓN</span>= Total de Puntos por Participante. </b></h5> <br>
         <p  style="color:#fff;  font-size: 19px;">
           Los participantes podrán revisar su avance dentro de la plataforma del concurso.
         </p>  
    <br><br>
  </div>
</template>

<script>
export default {
 data(){
      return{
            isMobile: this.$mobile(),
      }
 },
   computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
 mounted()
  {
      if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo9", true)
      } 
  }
}
</script>

<style>

</style>