<template>
  <div>
        <h1 :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
        'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Consideraciones Generales</h1>
        <br>
        <h6  style="color:#fff; font-size: 21px;">
            La siguiente información es confidencial y solo para uso interno de <br>
            Telefónica para que sirva de referencia en cuanto a ciertos criterios del <br>
            concurso.
        </h6>
        <br><br>
        <p style="text-align: justify; color:#fff;  font-size: 19px;">
            <b style="color:#000; font-size: 30px; margin-left: -30px;">1.</b>
             Todas los puntos estarán sujetos a evaluación por parte del área de Planificación B2C, en caso de detectar algún indicio de mala práctica imputable al participante,
             agencia o Call Center en daño o perjuicio de TEMM, Planificación, Regulatorio, Compliance, el director Comercial y/o el responsable del Canal se encontrarán facultados
             para poder penalizar al participante restándole puntos o descalificándolo de la competencia, independientemente de cualquier otra acción a tomarse fuera del concurso dependiendo
             de la gravedad del asunto.
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -30px;">2.</b>
             Si algún Canal nota alguna anomalía en la conducta o participación de algún competidor por lo que considere que se debe de descalificar,
             deberá contactar inmediatamente e informarlo tanto a Planificación como a las áreas de Relaciones Laborales, Personas, Compliance y Regulatorio,
             para que se revise el caso de manera puntual.
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -30px;">3.</b>
             En todos los casos ganan los participantes que logren acumular el mayor número de puntos a lo largo del concurso.  Una vez cerrado el conteo final No habrá cambios en los ganadores.  
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -30px;">4.</b>
             El número de ganadores por Canal será definido por el Director Comercial 
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -30px;">5.</b>
             El cumplimiento de cuota es equivalente a puntos, es decir: logro de 110% = 110 puntos.
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -30px;">6.</b>
             Los puntos se acumulan a lo largo de los 9 meses que dura el concurso: 1 abr. al 31 dic 2023.
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -30px;">7.</b>
             Solo se considerarán para el cuadro ganador, los participantes que hayan logrado mínimo el 80% de cuota. 
             Si el logro de cuotas es menor a 80% no sumará puntos. Eje: 72% = 0 puntos. SOLO APLICA A CANAL PROPIO. 
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -30px;">8.</b>
             Solo se tomarán en cuenta los puntos obtenidos de los desafíos si el participante completa la capacitación correspondiente al mismo mes de dichos juegos. SOLO APLICA A CANAL PROPIO Y RETAIL.
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -30px;">9.</b>
             De haber un empate se tomarán en cuenta las actividades contestadas correctamente en el orden que fueron entregadas.
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -42px;">10.</b>
             Si gana un Líder Mixto en 2 canales, se quedara con el premio del Canal que haya hecho más puntos y el premio del otro Canal pasará al segundo lugar.
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -42px;">11.</b>
             Todas las aclaraciones deberán de realizarse por medio de los canales oficiales y deberán de ser atendidos por los colaboradores que cada Canal nombró como su representante
             en la organización de la Convención.
             Las áreas de Patrocinios y Planificación No atenderán solicitudes que no se hagan de la manera antes mencionada y que no sean tratadas por medio de los enlaces de cada Canal.
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -42px;">12.</b>
             Las fuentes de información sobre la estructura de los canales provendrá de manera mensual de cada Canal y Planificación.
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -42px;">13.</b>
             Las fuentes de información para medir los logros de cuotas de manera mensual provendrán de los canales, así como de las áreas de Comisiones,
             Analítica y Planificación respectivamente y según sea el caso para cada canal.
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -42px;">14.</b>
             Las fuentes de información para medir la asistencia a las Capacitaciones de manera mensual provendrá del área de Capacitación.
                   <br><br>
            <b style="color:#000; font-size: 30px; margin-left: -42px;">15.</b>
             Las fuentes de información para medir los puntos de los desafíos (Trivias, mini juegos y pregunta secreta) provendrán del área de Patrocinios.
                   <br><br>
             <b style="color:#000; font-size: 30px; margin-left: -42px;">16.</b>
             La publicación de resultados está sujeta a los tiempos en que las áreas con las
             fuentes de información compartan los datos, tomando en cuenta que es a mes vencido y que deben de hacer el cierre de info del mes como normalmente se ejecuta en su operación.
                   <br><br>
             <b style="color:#000; font-size: 30px;  margin-left: -42px;">17.</b>
             <center><img src="elementos/considera.png" alt="Image"    :style="isMobile ? 'width: 100%;' : 'width: 80%;'"></center> 
        </p>
        <br>
  </div>
</template>

<script>
export default {
 data(){
      return{
            isMobile: this.$mobile(),
      }
 },
 mounted()
  {
      if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo4", true)
      } 
  }
}
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/guthen-bloots-personal-use');
</style>