<template>
  <div>
     <div id="subir"></div> 
    <center>
    <br><br>
       <b-row class="justify-content-center mt-2">
         <b-col cols="10" md="5" style="background: #29486F; border-radius: 8px;"  v-if="!isMobile">  
            <b-row class="justify-content-center" style=" margin: 8px;">
              <b-col cols="10" md="2">  
                <u style="color:#fff; font-size:19px; cursor: pointer;" @click="verSeccion(0)">Introducción</u>
              </b-col>
              <b-col cols="10" md="2">  
                <u style="color:#fff; font-size:19px; cursor: pointer;" @click="verSeccion(1)">Bases</u>
              </b-col>
              <b-col cols="10" md="2">  
                <u style="color:#fff; font-size:19px; cursor: pointer;"  @click="verSeccion(2)">Premios</u>
              </b-col>
              <b-col cols="10" md="2"> 
                <u style="color:#fff; font-size:19px; cursor: pointer;"  @click="verSeccion(3)">Mecánica</u>
              </b-col>
              <b-col cols="10" md="2">  
                <u style="color:#fff; font-size:19px; cursor: pointer;"  @click="verSeccion(4)">Ganadores</u>
              </b-col>
            </b-row>  
          </b-col>
          <b-col cols="10" md="4"  v-if="isMobile">   
              <v-btn :color="seccion == 0 ? '#5cb615' : '#29486F'" id="menus" :class="isMobile ? 'mt-5' : 'mt-2'" block  dark style="text-transform:capitalize;"  rounded="lg" @click="verSeccion(0)"> 
                  <span class="ml-3">Introducción</span>
              </v-btn>
               <v-btn :color="seccion == 1 ? '#5cb615' : '#29486F'" id="menus" :class="isMobile ? 'mt-5' : 'mt-2'" block  dark style="text-transform:capitalize;"  rounded="lg" @click="verSeccion(1)"> 
                  <span class="ml-3">Bases</span>
              </v-btn>
               <v-btn :color="seccion == 2 ? '#5cb615' : '#29486F'" id="menus" :class="isMobile ? 'mt-5' : 'mt-2'" block  dark style="text-transform:capitalize;"  rounded="lg" @click="verSeccion(2)"> 
                  <span class="ml-3">Premios</span>
              </v-btn>
               <v-btn :color="seccion == 3 ? '#5cb615' : '#29486F'" id="menus" :class="isMobile ? 'mt-5' : 'mt-2'" block  dark style="text-transform:capitalize;"  rounded="lg" @click="verSeccion(3)"> 
                  <span class="ml-3">Mecánica</span>
              </v-btn>
               <v-btn :color="seccion == 4 ? '#5cb615' : '#29486F'" id="menus" :class="isMobile ? 'mt-5' : 'mt-2'" block  dark style="text-transform:capitalize;"  rounded="lg" @click="verSeccion(4)"> 
                  <span class="ml-3">Ganadores</span>
              </v-btn>
              
          </b-col>
          <b-col cols="10" md="12" ></b-col>
          <b-col cols="10" md="6" >   
            <template v-if="seccion == 0">
              <br><br><br>
              <consideracion></consideracion>
            </template>
            <template v-if="seccion == 1">
              <br><br><br>
              <bases v-if="user.canal == 3"></bases>
              <basesEsp v-if="user.canal == 4"></basesEsp>
              <basesRet v-if="user.canal == 1"></basesRet>
              <basesT v-if="user.canal == 2"></basesT>
            </template>
            <template v-if="seccion == 2">
              <br><br><br>
              <premio v-if="user.canal == 3"></premio>
              <premioEsp v-if="user.canal == 4"></premioEsp>
              <premioRet v-if="user.canal == 1"></premioRet>
              <premioT v-if="user.canal == 2"></premioT>
            </template>
            <template v-if="seccion == 3">
              <br><br><br>
              <mecanica v-if="user.canal == 3"></mecanica>
              <mecanicaEsp v-if="user.canal == 4"></mecanicaEsp>
              <mecanicaRet v-if="user.canal == 1"></mecanicaRet>
              <mecanicaT v-if="user.canal == 2"></mecanicaT>
            </template>
            <template v-if="seccion == 4">
              <br><br><br>
              <ganador ></ganador>
            </template> 
            <span @click="subirArriba()" class="mdi mdi-arrow-up-thick mdi-36px" style="color:#fff; cursor: pointer;"> Regresar al Inicio</span> 
            <br>
          </b-col>
      </b-row>
     </center>
  </div>
</template>

<script>
import consideracion from "./challenge/Consideracion.vue"; 
import bases from "./challenge/Base.vue";
import basesEsp from "./challenge/BaseEsp.vue";
import basesRet from "./challenge/BaseRet.vue";
import basesT from "./challenge/BaseT.vue";
import premio from "./challenge/Premio.vue";
import premioEsp from "./challenge/premios/PremioEsp.vue";
import premioRet from "./challenge/premios/PremioRet.vue";
import premioT from "./challenge/premios/PremioT.vue";
import mecanica from "./challenge/Mecanica.vue";
import mecanicaEsp from "./challenge/mecanica/MecanicaEsp.vue";
import mecanicaRet from "./challenge/mecanica/MecanicaRet.vue";
import mecanicaT from "./challenge/mecanica/MecanicaT.vue";
import ganador from "./challenge/Ganadores.vue";
export default {
  name: 'Inicio',
  components: {
    consideracion,
    bases,
    premio,
    mecanica,
    ganador,
    basesEsp,
    basesRet,
    basesT,
    premioEsp,
    premioRet,
    premioT,
    mecanicaEsp,
    mecanicaRet,
    mecanicaT
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false, 
      seccion:0
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    } 
  },
  methods:{
    verSeccion(id){
      this.seccion = id;
      if (this.isMobile)
      {
       window.scrollTo(0,300);
      }
    },
    subirArriba(){
      // this.$scrollTo("#subir");
       window.scrollTo(0, -500);
    }
  },
  mounted(){
     if (this.isMobile)
      {
        window.scrollTo(0, 370);
      }
  }
}
</script>