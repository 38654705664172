<template>
  <div>
        <!-- <h1 :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
        'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Mécanica</h1>   -->
        <img  src="elementos/mecanica2.png" class="rounded" alt="Image"  > 
        <br>
        <h6  style="color:#fff; text-align: center;  font-size: 19px;">
           Eje.1 - Logros:  El objetivo de este eje es superar las cuotas comerciales de manera
            mensual, ya que los puntos que se pueden obtener están 100% relacionados con
            el cumplimiento de las cuotas mensuales.

        </h6>
        <br>
        <h4 style="color:#29486F;"><b>Líderes y KAMs</b></h4>
        <br>
        <p style="text-align: justify; color:#fff;  font-size: 19px;">
            <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b>
              Cumplimiento cuota mensual de prepago.
              <br><br>
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">2.</b>
              El cumplimiento de cuota es equivalente a puntos, es decir: logro de 110% = 110 puntos. 
              <br><br> 
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">3.</b>
             Los puntos se acumulan a lo largo de los 9 meses que dura el concurso: 1 abril al 31 de diciembre 2023.
        </p>
       <br>
        <h6  style="color:#fff; text-align: justify;  font-size: 19px;">
           Eje.2 - Capacitaciones:  El objetivo de este eje es participar y completar las capacitaciones
            que se vayan proponiendo dentro del juego a lo largo de los meses del concurso, al concluirlas dentro del tiempo establecido, el participante obtendrá 40 puntos por cada una.
        </h6>
        <br>
  </div>
</template>

<script>
export default {
 data(){
      return{
            isMobile: this.$mobile(),
      }
 },
 mounted()
  {
      if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo6", true)
      } 
  }
}
</script>

<style>

</style>