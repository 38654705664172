<template>
  <div>
        <!-- <h1 :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
        'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Mecanica</h1>   -->
        <img  src="elementos/mecanica2.png" class="rounded" alt="Image"  > 
        <br>
        <h6  style="color:#fff; text-align: center;  font-size: 19px;">
           <b>Eje.1 - Logros:</b>  El objetivo de este eje es superar las cuotas comerciales de manera mensual,
            ya que los puntos que se pueden obtener están 100% relacionados con el cumplimiento de las cuotas mensuales. 
        </h6>
        <br>
        <h4 style="color:#29486F;" v-if="user.perfil == 'Ejecutivo' && user.tipo_ejecutivo == 'Interno'" ><b>Ejecutivos Internos</b></h4>
        <h4 style="color:#29486F;" v-if="user.perfil == 'Ejecutivo' && user.tipo_ejecutivo == 'Externo'" ><b>Ejecutivos Externos</b></h4>
        <h4 style="color:#29486F;" v-if="user.perfil == 'Gerente'" ><b>Gerentes</b></h4>
        <h4 style="color:#29486F;" v-if="user.perfil == 'Coordinador'" ><b>Líder</b></h4>
        <br>
        <p style="text-align: justify; color:#fff;  font-size: 19px;">
            <template v-if="user.perfil == 'Ejecutivo' && user.tipo_ejecutivo == 'Interno'">
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b>
               Cuota mensual de prepago, pospago, terminales.
            </template>
            <template v-if="user.perfil == 'Gerente' || user.perfil == 'Ejecutivo' && user.tipo_ejecutivo == 'Externo'">
              <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b>
               Cuota mensual por pdv de prepago, pospago, terminales y conversión pospago.
            </template>
            <template v-if="user.perfil == 'Coordinador'">
               <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b>
               Cuota por líder (clúster) mensual de prepago, pospago, terminales y conversión pospago.
            </template>
              <br><br>
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">2.</b>
              El cumplimiento de cuota es equivalente a puntos, es decir: logro de 110% = 110 puntos. 
              <br><br>
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">3.</b>
              Los puntos se acumulan a lo largo de los 9 meses que dura el concurso: 1 abril al 31 de diciembre 2023
              <br><br>
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">4.</b>
               Solo se considerarán para el cuadro ganador, los participantes que hayan logrado mínimo el 80% de cuota.  Si el logro de cuotas es menor a 80% no sumará puntos.
               Eje: 72% = 0 puntos.
        </p> 
       <br>
        <h6  style="color:#fff; text-align: justify;">
           <b>Eje.2 - Acelerador Conversión Pospago:</b>  El objetivo de este eje es impulsar la conversión pospago.
        </h6>
        <br>
        <h4  style="color:#29486F; text-align: center;">
          <b>Esquema de Aceleradores</b>
        </h4>
        <br>
        <p style="text-align: justify; color:#fff;  font-size: 19px;" >
            <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b>
            Se otorgaran 50 puntos adicionales a los pdv que al final de mes tengan una conversión pospago por encima de la media nacional.
        </p>
        <br>
        <h6  style="color:#fff; text-align: justify;">
           <b>Eje.3 - Capacitaciones:</b>  El objetivo de este eje es participar y completar las capacitaciones que se vayan proponiendo dentro del concurso y
           concluirlas de acuerdo al criterio de calificación dentro del mes establecido.
           Solo participan capacitaciones especificas, mismas que se notificarán como
           parte del concurso. El valor de cada Capacitación es de 40 puntos por punto de venta.
           Los 40 puntos se distribuirán proporcionalmente de acuerdo al número de ejecutivos y gerente del pdv que tomen la capacitación.
           En el caso de lideres se otorgarán los 40 puntos de manera individual, si concluyeron la capacitación o no.

        </h6>
        <br>
        <h6  style="color:#fff; text-align: justify;">
           <b>Eje.4- Desafíos: </b>  El objetivo de este eje es cumplir con los diferentes tipos de actividades: trivias,
           retos y pistas ocultas, con lo que se podrán ir ganando puntos los cuales se sumarán a los puntos obtenidos de los logros de cuotas y las capacitaciones.
        </h6>
        <br>
        <p style="text-align: justify; color:#fff;  font-size: 19px;">
            <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">1.</b>
              El valor de cada actividad es de 10 puntos.
              <br><br>
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">2.</b>
              Los participantes deberá de responder de manera correcta las trivias, mini juegos y preguntas secretas en el orden que se vaya lanzado. <br> 
                •	Trivias - Las preguntas estarán relacionadas con la oferta comercial, información presentada en boletines o comunicados comerciales, plataformas, atención al cliente, etc.  <br>
                •	Mini juegos - Completar lo juegos de manera correcta.  <br>
                •	Pregunta secreta - pregunta abierta.
              <br><br>
             <b style="color:#29486F; font-size: 20px;  margin-left: -20px;">3.</b>
              <b> Solo se tomarán en cuenta los puntos obtenidos de los desafíos si el
              participante completa la capacitación correspondiente al mismo mes.</b> De haber un empate
              se tomarán en cuenta las actividades contestadas correctamente en el orden que fueron entregadas.
        </p>
       <br>
  </div>
</template>

<script>
export default {
 data(){
      return{
            isMobile: this.$mobile(),
      }
 },
   computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
 mounted()
  {
      if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo5", true)
      } 
  }
}
</script>

<style>

</style>