var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"rounded",attrs:{"src":"elementos/mecanica2.png","alt":"Image"}}),_c('br'),_vm._m(0),_c('br'),(_vm.user.perfil == 'Lider')?[_vm._m(1),_c('br'),_vm._m(2),_c('br')]:[_vm._m(3),_c('br'),_vm._m(4),_c('br'),_vm._m(5),_c('br'),_vm._m(6),_c('br')],_vm._m(7),_c('br'),_vm._m(8),_c('br'),_vm._m(9),_c('br'),(_vm.user.perfil == 'Lider')?_c('h6',{staticStyle:{"color":"#fff","text-align":"justify","font-size":"19px"}},[_c('b',[_vm._v("Eje.3 - Capacitaciones:")]),_vm._v(" El objetivo de este eje es participar y completar las capacitaciones que se vayan proponiendo dentro del concurso y concluirlas dentro del mes establecido. El valor de cada Capacitación es de 40 puntos. ")]):_c('h6',{staticStyle:{"color":"#fff","text-align":"justify","font-size":"19px"}},[_c('b',[_vm._v("Eje.3 - Capacitaciones:")]),_vm._v(" El objetivo de este eje es participar y completar las capacitaciones que se vayan proponiendo dentro del concurso. Se entregará al Canal el listado con las personas que se capacitaron para que las Agencias los consideren dentro de sus ganadore seleccionados. ")]),_c('br'),(_vm.user.perfil == 'Lider')?[_vm._m(10),_c('br'),_vm._m(11),_c('br')]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticStyle:{"color":"#fff","text-align":"center","font-size":"19px"}},[_c('b',[_vm._v("Eje.1 - Logros:")]),_vm._v(" El objetivo de este eje es superar las cuotas comerciales de manera mensual, ya que los puntos que se pueden obtener están 100% relacionados con el cumplimiento de las cuotas mensuales. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"color":"#29486F"}},[_c('b',[_vm._v("Líderes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify","color":"#fff","font-size":"19px"}},[_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("1.")]),_vm._v(" Cumplimiento cuota mensual de "),_c('b',[_vm._v("prepago, terminales e ingresos.")]),_c('br'),_c('br'),_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("2.")]),_vm._v(" El cumplimiento de cuota es equivalente a puntos, es decir: logro de 110% = 110 puntos. "),_c('br'),_c('br'),_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("3.")]),_vm._v(" Los puntos se acumulan a lo largo de los 9 meses que dura el concurso: 1 abril al 31 de diciembre. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"color":"#29486F"}},[_c('b',[_vm._v("Promotores")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify","color":"#fff","font-size":"19px"}},[_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("1.")]),_vm._v(" Se escogerán los mejores PDV por región según el mayor logro de cuotas a lo largo del concurso. Las Agencias seleccionarán al número de promotores que les indique la tabla de ganadores, de estos PDV, según los criterios de premiación. "),_c('br'),_c('br'),_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("2.")]),_vm._v(" Cumplimiento cuota mensual de "),_c('b',[_vm._v("prepago y terminales Alta Temm.")]),_c('br'),_c('br'),_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("3.")]),_vm._v(" El cumplimiento de cuota es equivalente a puntos, es decir: logo de 110% = 110 puntos. "),_c('br'),_c('br'),_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("4.")]),_vm._v(" Los puntos se acumulan a lo largo de los 9 meses que dura el concurso: 1 abril al 31 de diciembre. "),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"color":"#29486F"}},[_c('b',[_vm._v("Supervisores")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify","color":"#fff","font-size":"19px"}},[_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("1.")]),_vm._v(" Se utilizarán los clústeres de los líderes internos para determinar el cumplimiento y del clúster ganador, las Agencias seleccionarán a los mejores supervisores por región (suma de puntos de venta a cargo) con el mejor logro de cuotas a lo largo del concurso. "),_c('br'),_c('br'),_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("2.")]),_vm._v(" Cumplimiento cuota mensual de "),_c('b',[_vm._v("prepago y terminales Alta Temm.")]),_c('br'),_c('br'),_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("3.")]),_vm._v(" El cumplimiento de cuota es equivalente a puntos, es decir: logo de 110% = 110 puntos. "),_c('br'),_c('br'),_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("4.")]),_vm._v(" Los puntos se acumulan a lo largo de los 9 meses que dura el concurso: 1 abril al 31 de diciembre. "),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticStyle:{"color":"#fff","text-align":"justify"}},[_c('b',[_vm._v("Eje.2 - Aceleradores:")]),_vm._v(" El objetivo de este eje es impulsar el cumplimiento de cuotas y premiar el sobre cumplimiento de las mismas de manera mensual: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticStyle:{"color":"#29486F"}},[_c('b',[_vm._v("Esquema de Aceleradores")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify","color":"#fff","font-size":"19px"}},[_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("1.")]),_vm._v(" Se otorgaran 10 puntos adicionales a quien alcance entre el 95% y 100% de cumplimiento de cuota. "),_c('br'),_c('br'),_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("2.")]),_vm._v(" Se otorgaran 20 puntos adicionales a partir de cumplimiento de 101% de cuota. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticStyle:{"color":"#fff","text-align":"justify","font-size":"19px"}},[_c('b',[_vm._v("Eje.4 - Desafíos:")]),_vm._v(" El objetivo de este eje es participar y completar las capacitaciones que se vayan proponiendo dentro del concurso. Se entregará al Canal el listado con las personas que se capacitaron para que las Agencias los consideren dentro de sus ganadores seleccionados. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"justify","color":"#fff","font-size":"19px"}},[_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("1.")]),_vm._v(" El valor de cada actividad es de 10 puntos. "),_c('br'),_c('br'),_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("2.")]),_vm._v(" Los participantes deberán de responder de manera correcta las trivias, mini juegos y preguntas secretas en el orden que se vayan lanzando. "),_c('br'),_vm._v(" • Trivias - Las preguntas estarán relacionadas con la oferta comercial, información presentada en boletines o comunicados comerciales, plataformas, atención al cliente, etc. "),_c('br'),_vm._v(" • Mini juegos - Completar lo juegos de manera correcta. "),_c('br'),_vm._v(" • Pregunta secreta - pregunta abierta "),_c('br'),_c('br'),_c('b',{staticStyle:{"color":"#29486F","font-size":"20px","margin-left":"-20px"}},[_vm._v("3.")]),_vm._v(" Solo se tomarán en cuenta los puntos obtenidos de los desafíos si el participante completa la capacitación correspondiente al mismo mes. De haber un empate se tomarán en cuenta las actividades contestadas correctamente en el orden que fueron entregadas. ")])
}]

export { render, staticRenderFns }