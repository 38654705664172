<template>
  <div>
      <h1 :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
        'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'"> 
         Bases del Concurso 
            <span v-if="user.canal == 3" :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
            'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Propio</span>
            <span v-if="user.canal == 2" :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
            'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Telemarketing</span>
            <span v-if="user.canal == 1" :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
            'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Retail</span> 
            <span v-if="user.canal == 4" :style="isMobile ? 'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 55px;' : 
            'color:#fff; font-family:Guthen Bloots Personal Use, sans-serif; font-size: 70px;'">Especialista</span>
        </h1>   
        <br><br>
        <h4 style="color:#29486F;"><b>Objetivo</b></h4>
        <br>
        <p style="color:#fff; text-align: center; font-size: 19px;">
          Queremos premiar el esfuerzo y dedicación de nuestra fuerza de venta a nivel nacional,
          evaluando su desempeño en todos los ámbitos como su conocimiento del negocio, nivel de capacitación y especialmente el cumplimiento constante de las cuotas comerciales.
        </p>
        <br>
        <h4 style="color:#29486F;"><b>Fecha</b></h4>
        <br>
        <p style="color:#fff; text-align: center; font-size: 19px;">
          Del 1° de abril al 31 de diciembre 2023
        </p>
        <br>
        <h4 style="color:#29486F;"><b>Quien participa</b></h4>
        <br>
        <p style="color:#fff; text-align: center; font-size: 19px;">
         Participan Ejecutivos, Gerentes y Líderes de todas las regiones. <br>
         Quedan inscritos automáticamente y se empieza a contabilizar el cumplimiento de cuota desde el 1° de abril. 
        </p>
        <br>
  </div>
</template>

<script>
export default {
 data(){
      return{
            isMobile: this.$mobile(),
      }
 },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
 mounted()
  {
      if (this.isMobile)
      {
        this.$bus.$emit("cambi_fo6", true)
      } 
  }
}
</script>

<style>

</style>